import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		pmid: window.pmid,
		teacher: {},
		leftmenu: 0,
		config: {
			logo: "",
			leftlogo: "",
			mainbg: "",
			schoolname: "",
			loginbg: "",
			favico: "",
			pmid:0
		}
	},
	mutations: {
		setTeacher(state, value) {
			state.teacher = value
		},
		setPmid(state, value) {
			state.pmid = value
		},
		showSmenu(state, value) {
			state.leftmenu = value
		},
		setConfig(state, value) {
			state.config = value
		},
	},
	actions: {
		setTeacher(context, value) {
			context.commit("setTeacher", value);
		},
		setPmid(context, value) {
			context.commit("setPmid", value);
		},
		showSmenu(context, value) {
			context.commit("showSmenu", value);
		},
		setConfig(context, value) {
			context.commit("setConfig", value);
		},
	},
	modules: {}
})
