import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import aes from './utils/aes.js'
Vue.config.productionTip = false
import axios from "axios"
Vue.prototype.$http = axios

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import echarts from "echarts"
Vue.prototype.$echarts = echarts

import Print from 'vue-print-nb'
Vue.use(Print);

import '../public/css/global.less'
//图片查看器
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.use(Viewer)

//首页大屏
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)


//获取用户token
let token = null;
let getToken = _ => {
	if (!token) {
		if (sessionStorage.getItem("auth")) { //从session中取到token后，再解密
			token = (sessionStorage.getItem("auth"))
		}
	}
	return token;
}

axios.interceptors.request.use(req => {
	req.headers["Authorization"] = getToken();
	if (req.data) {
		if (!req.data.pmid) {
			if (sessionStorage.getItem("userinfo")) {
				req.data.pmid = JSON.parse((sessionStorage.getItem("userinfo"))).pmid
			}

		}

	}
	if (req.params) {

		if (!req.params.pmid) {
			if (sessionStorage.getItem("userinfo")) {
				req.params.pmid = JSON.parse((sessionStorage.getItem("userinfo"))).pmid
			}
		}
	}

	if (req.url.indexOf("http") >= 0) {

	} else {
		//req.url = "//gzdc-api.qushixi.org.cn/" + req.url
		if (req.url.indexOf("/") == 0) {
			req.url = "//gzdc-api.qushixi.org.cn" + req.url
		} else {
			req.url = "//gzdc-api.qushixi.org.cn/" + req.url
		}

		//req.url = "//127.0.0.1:9097/" + req.url
		// req.url = "http://nwtest.mmteck.cn:9000/" + req.url

	}
	return req
}, error => {
	return Promise.reject(error)
});
axios.interceptors.response.use(response => {

	if (response.data.code == 401 || response.data.name == 'TokenExpiredError') {

		// sessionStorage.clear();
		window.location.href = "./#/login"
		// 	encodeURIComponent(window.location.href)

	} else {

		return response
	}
}, error => {

	if (error && error.response && error.response.status == 401) {
		sessionStorage.clear();
		window.location.href = window.auth.server + "?pmid=" + window.pmid + "&" + window.auth.redirect + "=" +
			encodeURIComponent(window.location.href)

	} else {
		return Promise.reject(error)
	}

})



Vue.prototype.uploadTemp = (fileObj) => {
	console.log("初始化")
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1);
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI0Dd9MsOqnVoj',
		signature: 'Xst4hA4qiPYO5HXG/8CcQgDTm9U=',
		success_action_status: "200",

	};
	var host = 'https://mmtemp.oss-cn-hangzhou.aliyuncs.com';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {


			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
				fileName
				.indexOf(
					".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function(theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function() {
						fileObj.onSuccess({
							src: outurl + "/" + fileKey + "?s=" + this.width + "_" + this
								.height,
							fileName: fileObj.file.name
						})

					};
				};

			} else {
				fileObj.onSuccess({
					src: outurl + "/" + fileKey,
					fileName: fileObj.file.name
				})
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}


Vue.prototype.uploadOss = (fileObj) => {
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI4G55gyD65iSg836oWSK4',
		signature: '7a7dfIH8bpoHXTeZxd+3xGS/g54=',
		success_action_status: "200",

	};
	var host = 'https://school-saas.oss-cn-hangzhou.aliyuncs.com';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {


			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
				fileName
				.indexOf(
					".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function(theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function() {
						fileObj.onSuccess({
							src: outurl + "/" + fileKey + "?s=" + this.width + "_" + this
								.height,
							fileName: fileObj.file.name
						})

					};
				};

			} else {
				fileObj.onSuccess({
					src: outurl + "/" + fileKey,
					fileName: fileObj.file.name
				})
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}

Vue.prototype.getIdx = (idx) => {
	switch (idx) {
		case 0:
			return "A"

		case 1:
			return "B"

		case 2:
			return "C"

		case 3:
			return "D"

		case 4:
			return "E"

		case 5:
			return "F"

		case 6:
			return "G"

		case 7:
			return "H"

		case 8:
			return "I"

		case 9:
			return "J"

		case 10:
			return "K"

	}

}

Vue.prototype.getQuery = (name) => {
	var n_url = window.location.href;
	n_url = n_url.replace("/#", "");
	if (n_url.indexOf("?") > 0) {
		n_url = n_url.split("?")[1]
	}
	var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
	var r = n_url.match(reg);
	if (r != null) return unescape(r[2]).split("#")[0];
	return null;
}




new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
