import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import indexframe from '../views/index.vue'
Vue.use(VueRouter)

const routes = [{
	path: '/ivst',
	name: 'ivst',
	component: resolve => require(['../views/h5/ivst'], resolve),
}, {
	path: '/p/:id',
	name: 'ivstplan',
	component: resolve => require(['../views/h5/ivstplan'], resolve),
}, {
	path: '/welcome',
	name: 'welcome',
	component: resolve => require(['../views/h5/welcome'], resolve),
}, {
	path: '/login',
	name: 'login',
	component: resolve => require(['../views/login'], resolve),
}, {
	path: '/rep',
	name: 'rep',
	component: resolve => require(['../views/rep'], resolve),
}, {
	path: '/report',
	component: resolve => require(['../views/pages/plan/report'], resolve)

},{
	path: '/reporttxt',
	component: resolve => require(['../views/pages/plan/reportTxt'], resolve)

},{
	path: '/reportMajor',
	component: resolve => require(['../views/pages/plan/reportMajor'], resolve)

}, {
	path: '/index',
	name: 'indexframe',
	component: indexframe,
	children: [
		{
			path: '/list',
			component: resolve => require(['../views/pages/list'], resolve)
		},
		{
			path: '/suggestion',
			component: resolve => require(['../views/pages/message/alumniSuggestion'], resolve)
		},
		{
			path: '/alumniInfo',
			name: '校友信息',
			component: resolve => require(['../views/pages/alumniInfo/alumniInfo'], resolve)
		},
		{
			path: '/event',
			component: resolve => require(['../views/pages/alumniInfo/event'], resolve)
		},
		{
			path: '/activity',
			component: resolve => require(['../views/pages/alumniInfo/activity'], resolve)
		},
		{
			path: '/forum',
			component: resolve => require(['../views/pages/alumniInfo/forum'], resolve)
		},
		{
			path: '/news1',
			name: '校内新闻',
			component: resolve => require(['../views/pages/news/news1'], resolve)
		},
		{
			path: '/news2',
			component: resolve => require(['../views/pages/news/news2'], resolve)
		}, {
			path: '/ivstlist',
			name: '问卷模板',
			component: resolve => require(['../views/pages/ivst/list'], resolve)
		}, {
			path: '/ivstLog',
			name: '发送记录',
			component: resolve => require(['../views/pages/ivst/ivst_send_log'], resolve)
		},
		{
			path: '/edit/:id',
			component: resolve => require(['../views/pages/ivst/edit'], resolve)
		},
		{
			path: '/campus',
			name: '校园风景',
			component: resolve => require(['../views/pages/photoAlbum/campus'], resolve)
		},
		{
			path: '/classPhoto',
			name: '班级相册',
			component: resolve => require(['../views/pages/photoAlbum/classPhoto'], resolve)

		},
		{
			path: '/seting',
			component: resolve => require(['../views/pages/seting/ngz_seting'], resolve)

		},
		{
			path: '/plan',
			name: '问卷计划',
			component: resolve => require(['../views/pages/plan/plan'], resolve)

		},
		{
			path: '/suverySum',
			component: resolve => require(['../views/pages/plan/suverySum'], resolve)

		},
		{
			path: '/fillLog',
			name: '填写记录',
			component: resolve => require(['../views/pages/plan/plan_fill_log'], resolve)
		},
		{
			path: '/homePage',
			component: resolve => require(['../views/home/home'], resolve)

		},
		{
			path: '/major',
			component: resolve => require(['../views/pages/seting/major'], resolve)

		},
		{
			path: '/teachers',
			component: resolve => require(['../views/pages/teacher/teachers'], resolve)

		}, {
			path: '/students',
			component: resolve => require(['../views/pages/teacher/students'], resolve)

		},
		{
			path: '/jtseting',
			component: resolve => require(['../views/pages/seting/jt_seting'], resolve)

		},
	]
},
	{
		path: '/daInquiry',
		component: resolve => require(['../views/pages/GraduateFileInquiry/pc_index.vue'], resolve)
	},
	{
		path: '/daInquiry_h5',
		component: resolve => require(['../views/pages/GraduateFileInquiry/h5.vue'], resolve)
	},
]



const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
})


//获取url参数的方法，支持vue路由各种参数写法
const $request = function(name) {
	var params = [];
	var value = null;
	if (window.location.href.indexOf(name + "=") >= 0) {
		let q = window.location.href.split("?")[1];
		if (q.indexOf("#") > 0) {
			q = q.split("#")[0];
		}
		params = q.split("&");
		for (let p of params) {
			if (p.indexOf(name + "=") >= 0) {
				value = p.replace(name + "=", "").replace(/\//g, "")
				break;
			}
		}
	}
	if (value && value != undefined && value != "undefined") {
		return value
	} else {
		return "";
	}
}


//路由进入之前检查是否登录
router.beforeEach((to, from, next) => {
	let auth = $request("auth"); //检查url是否带有登录字符串
	if (auth) { //检查本地是否登录
		window.sessionStorage.setItem("auth", auth); //将登录字符串保存到缓存
		next();
	} else if (window.sessionStorage.getItem("auth")) {
		next();
	} else {
		next();
	}


})



export default router
