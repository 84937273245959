<template>
	<div>
		<div class="header">
			<div class="menubar">
				<img v-if="$store.state.config.pmid==3" alt="" :src="'./img/schoolNameLogo.png'">
				<img v-if="$store.state.config.pmid==7" alt="" :src="'./img/schoolNameLogo_7.jpg'">
				<img v-if="$store.state.config.pmid==8" alt="" :src="'./img/schoolNameLogo_8.jpg'">
				<img v-if="$store.state.config.pmid==9" alt="" :src="'./img/schoolNameLogo_9.jpg'">
			</div>
			<div class="headright">
				<!-- <div class="moudelname"></div> -->
				<div  style="position: absolute; right:180px ; line-height: 50px; width: 300px; font-size: 14px;">
					<div  style="display: inline-block;">
						
					    {{ weather.location ? weather.location.name : '' }}
					    {{ weather.now ? weather.now.text : '' }}
					    {{ weather.now ? weather.now.wind_dir : '' }}
					    {{ weather.now ? weather.now.wind_class : '' }}
					
					</div>
					
				
				</div>
				<div style=" float: right; width: 230px;">
					<div class="nickname  flexCenter">
						<span style="cursor: pointer; border: 0; outline: none; color: #888;">
							欢迎您:{{ userinfo.real_name }} <!-- <i class="el-icon-arrow-down"></i> -->
						</span>
					</div>
					<div class="headimg">


						<img :src="'./img/head.jpg'" alt="">
					</div>
				</div>


			</div>

		</div>
		<div class="menuline"></div>



		<div :class="['leftmenu'] ">
			<div style="width: 140px; margin: 0 auto; height: 100%;">
				<div style="height: 70px;"></div>

				<div class="smenubox" style=" border-radius: 6px;">
					<div :class="['menuitem',nowsMenu==item.id?'menuckd':'']" v-for="(item,idx) in Smenus" :key="idx"
						@click="goPage(item)">
						<i :class="item.icon"></i> {{item.title}}
						<div class="ckdtail">

						</div>
					</div>
				</div>
			</div>
		</div>





	</div>
</template>

<script>
	import aes from "../../utils/aes.js";
	import weather from './weather.js'
	export default {
		data() {
			return {

				avatar: "",
				Menus: [],
				Smenus: [],
				nowbMenu: 0,
				nowsMenu: "",
				userinfo: {real_name:"",pmid:0},
				isShowModal: false,
				isShowYearModal: false,
				ltmenuHide: true,
				pwdForm: {
					oldPwd: "",
					newPwd1: "",
					newPwd2: "",
				},
				weather: {},
			};
		},
		mounted() {
			let Smenus = [{
				id: 11,
				icon: "",
				title: "数据面板",
				path: "/homePage"
			},{
				id: 1,
				icon: "",
				title: "问卷计划",
				path: "/plan"
			},{
				id: 2,
				icon: "",
				title: "问卷模板",
				path: "/ivstlist"
			},{
				id: 5,
				icon: "",
				title: "学生管理",
				path: "/students"
			},]
			this.Smenus = Smenus
			//this.getRoleMenu();
			for(let item of Smenus){
				if(this.$route.path==item.path){
					this.nowsMenu = item.id
				}
			}
      let arr = ['/daInquiry_h5','/daInquiry']
      if(arr.indexOf(this.$route.path)>-1){
      }else {
        if (sessionStorage.getItem("userinfo")) {
          this.userinfo = JSON.parse(sessionStorage.getItem("userinfo"))
        } else {
          this.$http.post("api/token_login", {}).then(res => {

            if (res.data && res.data.data) {
              sessionStorage.setItem("userinfo", JSON.stringify(res.data.data))
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error',
                offset: '600'
              });

            }


          }, err => {

          })
        }

      }
			
			
			// weather.getWeather().then(res => {
			//     // console.log(res.result)
			//     this.weather = res.result
			// })
		},
		watch: {
			Smenus: function(n, o) {
				this.$parent.leftMenus = n;
			},
		},
		methods: {

			getRoleMenu() {

				this.$http.post("/api/leave_teacher_menu").then(res => {
					this.Menus = res.data
					//this.getConfig();
					this.getTeacherInfo();
					for (let item of this.Menus) {
						if (item.path && this.$route.path.indexOf(item.path) >= 0) {
							this.nowsMenu = 0
							this.nowbMenu = item.id
							this.$store.dispatch("showSmenu", 0)
						} else {
							for (let sitem of item.children) {
								if (sitem.path && this.$route.path.indexOf(sitem.path) >= 0) {
									this.nowsMenu = sitem.id
									this.nowbMenu = item.id
									this.Smenus = item.children;
									this.$store.dispatch("showSmenu", 1)
									break
								}
							}
						}
					}
				})
			},
			goBMenu(item) {
				this.Smenus = item.children;
				this.nowbMenu = item.id;
				if (this.Smenus && this.Smenus.length > 0) {
					this.goPage(this.Smenus[0]);
					this.nowsMenu = this.Smenus[0].id;
					this.$store.dispatch("showSmenu", 1)
				} else if (item.path) {
					if (this.$route.path != item.path) {
						this.$router.push(item.path);
					}
					this.$store.dispatch("showSmenu", 0)
				}
			},
			goPage(item) {
				if (this.$route.path != item.path) {
					this.$router.push(item.path)
					this.nowsMenu = item.id
				}
			},
			setYear() {
				localStorage.setItem("year", this.year);
				window.location.reload();
			},
			handleCommand(e) {
				console.log(e);
				if (e == "updPwd") {
					this.isShowModal = true;
				} else if (e == "logout") {
					localStorage.clear();
					sessionStorage.clear();
					this.$router.push("/login");
				} else if (e == "changeYear") {
					this.isShowYearModal = true;
				}
			},
			savePwd() {
				if (!this.pwdForm.oldPwd) {
					this.$message.error("请输入原密码");
					return;
				}

				if (!this.pwdForm.newPwd1) {
					this.$message.error("请输入新密码");
					return;
				}
				if (!this.pwdForm.newPwd2) {
					this.$message.error("请再次输入新密码");
					return;
				}
				if (this.pwdForm.newPwd1 != this.pwdForm.newPwd2) {
					this.$message.error("两次密码不一致");
					return;
				}
				this.$http.post("/api/reset_myself_pwd", this.pwdForm).then((ok) => {
					if (ok.data.code) {
						if (ok.data.code == 500) {
							this.$message.error(ok.data.msg);
						} else {
							this.$message.error("保存失败");
						}
					} else {
						this.$message.success("修改成功");

						this.isShowModal = false;
						this.$router.push("/login");
					}
				});
			},
			getConfig() {
				this.$http.post("/api/sch_configs").then(res => {
					for (let k of res.data) {

						if (k.ktype == 'logo2') {
							//this.leftLogo = k.kvalue
						}
					}


				})
			},
			getTeacherInfo() {

				this.$http.post("/api/get_teacher_info").then(res => {
					this.avatar = res.data.avatar
					this.userName = res.data.username
					this.$store.dispatch("setTeacher", res.data)
				})
			},

		},
	};
</script>
<style scoped lang="less" type="text/less">
	.moudelname{float: left; margin-top: 20px; margin-left: 20px;}
	
	
.leftmenu {
  width: 170px;
  height: 100%;
  position: absolute;
  
  transition: all .3s;
  transform-origin: left;
 

  .menuitem {
    padding: 10px;
    text-align: left;

    cursor: pointer;
    margin: 0 auto;

	font-size: 14px;
	background-color: #fff;
	border-bottom: 1px solid #eee;
	border-radius: 4px;
  }
.menuitem:hover{ background-color: #eee;}
  // .menuitem:first-child {
  //   border-top-left-radius: 4px;
  //   border-top-right-radius: 4px;
  // }

  // .menuitem:last-child {
  //   border-bottom: 1px solid #0794b3;
  //   border-bottom-left-radius: 4px;
  //   border-bottom-right-radius: 4px;
  //   border-radius: 4px;
  // }

  .menuckd {
     background-color: #b9cbdf !important;
  }
  .menuckd:hover{ background-color: #b9cbdf;}
}
.menuline{ height: 2px; border: 2px solid #b9cbdf; position: absolute; top: 50px; width: 100%; background-color: #e1e9f9;}
.hidemenu {
  transform: scale(0, 1);
}

.smenubox {
		width: 100%;
		
		margin: 0 auto;
		background-color: #fff;
		height: 86%;
	
	}

	.hidemenu {
		transform: scale(0, 1);
	}

	.menuitem {
		padding: 10px;
		text-align: left;
		cursor: pointer;
		margin: 0 auto;

		color: #333333;

	}

	.menuckd {

		color: #1890ff;
		box-shadow: 0 0 6px 2px rgba(24, 144, 255, 0.2) inset;
		border-top-left-radius: 20px;
		border-bottom-left-radius: 20px;
		position: relative;
		background-color: #fafbfc;
	}

	// .menuckd .ckdtail {
	// 	width: 40px;
	// 	height: 80px;
	// 	position: absolute;
	// 	top: -20px;
	// 	right: -5px;
	// 	// background-image: url(//aiwx.org.cn/static/images/tail.png);
	// 	background-size: auto 100%;

	// 	background-repeat: no-repeat;
	// 	background-color: #fafbfc;



	// }

.header {
  height: 50px;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
	background-color: #fff;

  .menubar {
    width: 200px;
    height: 100%;
    float: left;
    color: #fff;
    text-align: center;
    line-height: 50px;

    img {
      height: 90%;
      margin-top: 2px;
    }
  }

  .headright {
    height: 100%;
    margin-left: 200px;
	
    position: relative;
	text-align: center;

    .bigmenu {
     
      height: 40px;
	  padding-top: 12px;
      color: #626874;
      .bmitem {
        height: 100%;
        padding: 5px 40px 0px 40px;
        border-radius: 4px;
        cursor: pointer;
		display: inline-block;
		vertical-align: top;
		
		margin-right: 10px;
	
      }

      .bmckd {
        
		background-image: url(//aiwx.org.cn/static/images/menubg.png);
		background-size: 100% 100%;
		line-height: 50px;
		padding-top: 0;
		color: #409efe;
		font-weight: bolder;
      }
    }

    .nickname {
      position: absolute;
      right: 60px;
      //top: 10px;
      height: 100%;
      line-height: 100%;
      color: #fff;
      padding: 0 20px;
      border-radius: 4px;
    }

    .headimg {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      right: 20px;
      border: 1px solid  #0794b3;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}
</style>
